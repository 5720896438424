/* You can add global styles to this file, and also import other style files */
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

app-toast,
.toast-class {
    z-index: 100;
}

.bg-green {
    background-color: #C8E6C9;
}

.bg-pink {
    background-color: #FFD3D5;
}

// .bg-bark-light{
//     background-color: #EFEFEF;
// }
.bg-grey {
    background-color: #F6F6F6;
}

.bg-profile-blue {
    background-color: #7F67BE;
}

.bg-profile-navyblue {
    background-color: #063165;
}

.bg-org-color {
    background-color: var(--Background, #EFEFEF) !important;
}

.bg-card-color {
    background-color: #F3F3FA !important;
}

.bg-text-color {
    background-color: #E2E2E9 !important;
}

.bg-transparent {
    background-color: transparent !important;
}

.bg-white {
    background-color: #ffffff !important;
}

.text_color_red {
    color: rgb(250, 45, 45);
}

.text_color_light {
    color: #74777F !important;
}

.text_color_on_bg {
    color: #44474F !important;
}

.hr_line .hr_position :nth-last-child(1){
    display: none;
} 
.cursor_pointer {
    cursor: pointer;
}

.login_card {
    width: 30vw;
}

.login_banner {
    height: 100vh;
}
.fs-14{
    font-size: 14px;
}
.fs-16{
    font-size: 16px;
}
.fs-12{
    font-size: 12px;
}
.line-height-15{
    line-height: 15px;
}
.page_header {
    .page_header_title {
        font-size: 20px;
        font-weight: 500;
    }
}
.height_30{
    height: 30px !important;
}

.border_radius_4{
    border-radius: 4px;
}

.back_icon {
    background-color: #F3F3FA !important;
    color: #44474F !important;
    border-radius: 0% !important;
    padding: 0 !important;
    font-size: 20px !important;
    max-width: 35px !important;
    max-height: 35px !important;
}

.back_icon:hover {
    border-radius: 0% !important;
}

.bulk_button {
    background-color: #FFFFFF;
    border-radius: 4px;

    .bulk_button_add {
        border-radius: 4px 0px 0px 4px !important;
        background-color: #D1F3FF !important;
        color: #56575B !important;
    }

    .bulk_button_view {
        background-color: #D1F3FF !important;
        margin-left: 1px !important;
        min-width: 30px !important;
        color: #35CDFF !important;
        border-radius: 0px 4px 4px 0px !important;
    }
}

.add_button{
    background-color: #0056B5 !important;
    color: #FAFBFC !important;
}

.submit_button{
    background-color: #0056B5 !important;
    color: #FAFBFC !important;
}

.cursor_pointer {
    cursor: pointer;
}

.textunderline {
    text-decoration: underline;
}

.box-shadow-none {
    box-shadow: none !important;
}

.loading-shade {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 56px;
    right: 0;
    background: rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.rate-limit-reached {
    max-width: 360px;
    text-align: center;
}

.mat_spinner {
    width: 50px !important;
}

.table-container {
    background-color: white;
    position: relative;
    min-height: 65vh;
    max-height: 65vh;
    overflow: auto;
}

table {
    width: 100%;
}

.mat-column-number,
.mat-column-state {
    max-width: 64px;
}

.mat-column-created {
    max-width: 124px;
}

@media only screen and (max-width: 600px) {
    .login_card {
        width: 90vw;
    }

    .login_banner {
        height: 20vh;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}